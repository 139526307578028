import { createHttpEndpoint } from '../../utils'

import type { FAQCategories } from './types/FAQCategories'
import type { SearchArticles } from './types/SearchArticles'
import type { SelfTroubleshootingArticlesList } from './types/selfTroubleshooting'

/**
 * List of categories
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/help-center-api/definition#operation/get-categories}
 */
export const getCategories = createHttpEndpoint<FAQCategories>({
  method: 'GET',
  operationId: 'getHelpCenterFAQCategories',
  path: '/help-center/api/v1/categories',
})

/**
 * Search for an article
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/help-center-api/definition#operation/get-articles-search}
 */
export const getArticlesSearch = createHttpEndpoint<SearchArticles>({
  method: 'GET',
  operationId: 'getHelpCenterFAQArticles',
  path: '/help-center/api/v1/articles/search',
})

export interface SelfTroubleshootingArticlesParams {
  /** Orderlineid */
  orderlineId: number
  /** Customerissues */
  customerIssues?: string | null
}

export const getSelfTroubleshootingArticles =
  createHttpEndpoint<SelfTroubleshootingArticlesList>({
    method: 'GET',
    operationId: 'self_troubleshooting_articles',
    path: '/help-center/api/v1/auth/articles/self-troubleshooting',
  })

<template>
  <ResolutionOptionCardShell
    data-qa="express-replace-option"
    data-test="express-replace-option"
    :icon="IconExpressReplacement"
    :option-type="RESOLUTION_OPTION_TYPE_ENUM.expressReplace"
    @select="handleSelect"
  >
    <template #title>
      {{ i18n(translations.title) }}
      <sup class="text-static-default-hi">BETA</sup>
    </template>

    <template #kicker>
      {{ i18n(translations.kicker) }}
    </template>

    <template #description>
      {{ i18n(translations.description) }}
    </template>

    <template #tags>
      <RevTag :label="i18n(translations.tag)" variant="info" />
    </template>

    <template #price>
      {{ i18n(translations.price) }}
    </template>
  </ResolutionOptionCardShell>
</template>

<script lang="ts" setup>
import { RESOLUTION_OPTION_TYPE_ENUM } from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevTag } from '@ds/components/Tag'
import { IconExpressReplacement } from '@ds/icons/IconExpressReplacement'

import ResolutionOptionCardShell from '../ResolutionOptionCardShell/ResolutionOptionCardShell.vue'

import translations from './ExpressReplaceResolutionOptionCard.translations'

const emit = defineEmits<{
  select: [value: string]
}>()

const i18n = useI18n()

function handleSelect(event: string) {
  emit('select', event)
}
</script>

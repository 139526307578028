export default {
  description: {
    id: 'dashboard_diagnosis_option_remote_assistance_description',
    defaultMessage:
      'Submit a help request and you’ll hear back from our tech experts within 1 day.',
  },
  kicker: {
    id: 'dashboard_diagnosis_option_remote_assistance_timeline',
    defaultMessage: 'Hear back in 1 day',
  },
  title: {
    id: 'dashboard_diagnosis_option_remote_assistance',
    defaultMessage: 'Get help via email',
  },
}

export default {
  description: {
    id: 'resolution_flow_resolution_option_chat_description',
    defaultMessage:
      'An expert will reply via chat as soon as possible. We’ll notify you by email when you have a response.',
  },
  kicker: {
    id: 'resolution_flow_resolution_option_chat_kicker',
    defaultMessage: 'Up to 1 business day',
  },
  title: {
    id: 'resolution_flow_resolution_option_chat_title',
    defaultMessage: 'Message an agent',
  },
}

<template>
  <ResolutionOptionCardShell
    data-qa="seller-repair-option"
    data-test="seller-repair-option"
    :icon="IconTools"
    :option-type="RESOLUTION_OPTION_TYPE_ENUM.sellerRepair"
    @select="handleSelect"
  >
    <template #title>
      {{ i18n(translations.title) }}
    </template>

    <template #kicker>
      {{ i18n(translations.kicker) }}
    </template>

    <template #description>
      {{ i18n(translations.description) }}
    </template>
  </ResolutionOptionCardShell>
</template>

<script lang="ts" setup>
import { RESOLUTION_OPTION_TYPE_ENUM } from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconTools } from '@ds/icons/IconTools'

import ResolutionOptionCardShell from '../ResolutionOptionCardShell/ResolutionOptionCardShell.vue'

import translations from './SellerRepairResolutionOptionCard.translations'

const emit = defineEmits<{
  select: [value: string]
}>()

const i18n = useI18n()

function handleSelect(event: string) {
  emit('select', event)
}
</script>

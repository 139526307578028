<template>
  <ResolutionOptionCardShell
    data-qa="chat-option"
    data-test="chat-option"
    :icon="IconHeadset"
    :loading
    :option-type="RESOLUTION_OPTION_TYPE_ENUM.chat"
    @select="handleSelect"
  >
    <template #title>
      {{ i18n(translations.title) }}
    </template>

    <template #kicker>
      {{ i18n(translations.kicker) }}
    </template>

    <template #description>
      {{ i18n(translations.description) }}
    </template>
  </ResolutionOptionCardShell>
</template>

<script lang="ts" setup>
import { RESOLUTION_OPTION_TYPE_ENUM } from '@backmarket/http-api/src/api-specs-resolution-engine/types/common'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconHeadset } from '@ds/icons/IconHeadset'

import ResolutionOptionCardShell from '../ResolutionOptionCardShell/ResolutionOptionCardShell.vue'

import translations from './ChatResolutionOptionCard.translations'
import type { ChatResolutionOptionsCardProps } from './ChatResolutionOptionCard.types'

defineProps<ChatResolutionOptionsCardProps>()

const emit = defineEmits<{
  select: [value: string]
}>()

const i18n = useI18n()

function handleSelect(event: string) {
  emit('select', event)
}
</script>

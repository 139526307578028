export default {
  description: {
    id: 'dashboard_diagnosis_option_live_chat_description',
    defaultMessage:
      'Chat live with a friendly human about any technical issues you’re experiencing with your device.',
  },
  kicker: {
    id: 'dashboard_diagnosis_option_live_chat_timeline',
    defaultMessage: 'Hear back in minutes',
  },
  title: {
    id: 'dashboard_diagnosis_option_live_chat',
    defaultMessage: 'Get live help via chat',
  },
}

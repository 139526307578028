export default {
  description: {
    id: 'dashboard_diagnosis_option_repaired_description',
    defaultMessage:
      'Return your item for free and it will be repaired within 3 business days of the seller receiving it and then sent back to you. You’ll get your free shipping label immediately in the next step. If the device is non-repairable, you could be eligible for a refund!',
  },
  kicker: {
    id: 'dashboard_diagnosis_seller_repair_timeline',
    defaultMessage: '7 to 10 days',
  },
  title: {
    id: 'dashboard_diagnosis_option_repaired',
    defaultMessage: 'Get it repaired - fastest solution!',
  },
}

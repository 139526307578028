export default {
  description: {
    id: 'dashboard_diagnosis_option_express_replacement_description',
    defaultMessage:
      "We'll replace it with another identical device sent to the same address. Send the original back once you receive your replacement so you're never without a device.",
  },
  kicker: {
    id: 'dashboard_diagnosis_express_replacement_kicker',
    defaultMessage: 'Not a day without a device',
  },
  title: {
    id: 'dashboard_diagnosis_express_replacement_title',
    defaultMessage: 'Get Express Replacement',
  },
  tag: {
    id: 'dashboard_diagnosis_express_replacement_tag',
    defaultMessage: 'Fastest solution',
  },
  price: {
    id: 'dashboard_diagnosis_express_replacement_price',
    defaultMessage: 'Free',
  },
}
